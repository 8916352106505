var texts = {
  en: {
    about: {
      welcome: '¿Why PROPLAT?',
      description: "PROPLAT offers ag-tech business services, connecting mexican growers with buyers in the United States, integrating produce transactions, logistics, financing and insurance; all in the same platform, removing intermediaries and maximizing profits in a safe and reliable way.",
      platformTitle: 'Platform',
      platformSection: 'Enjoy of our produce platform, our solution provides different tools to simplify key aspects of the industry.',
      insuranceTitle: 'Safe',
      insuranceSection: 'We guarantee safe transactions by having certified sellers and buyers, we use advanced security measures in transactions, maintain a reliable infrastructure and establish user protection policies.',
      logisticTitle: 'Logistics',
      logisticSection: 'We manage logistics efficiently through a step-by-step process designed to provide a seamless experience for our users.',
      financeTitle: 'Financing',
      financeSesction: 'We offer affordable financing options through a process that includes online application, approval based on credit evaluation and the ability to choose flexible payment plans.',
    },
    banner: {
      titleSection: 'Revolutionizing the produce industry',
      subtitleSection: 'We use technology to make fresh produce transactions more efficient and accesible for everyone, improving the supply chain and providing better oportunities to both suppliers and buyers.',
      buttonBuyer: 'Buy directly from Mexico',
      buttonProvider: 'Become a supplier',
    },
    buyer: {
      titleSection: 'Your Strategic Ally for all your produce purchases.',
      viewProviders: 'We are a one-stop shop, a complete solution to optimize the purchasing process through reliable suppliers.',
      Catalog: 'Catalog',
      Warehouses: 'Warehouses',
      Suppliers: 'Suppliers',
      Orders: 'Orders',
      Dispute: 'Dispute',
      User: 'User profile',
      buttonBuy: 'I want to buy',
    },
    provider: {
      titleSection: 'Become a supplier and start multiplying your sales',
      viewItems: 'In the produce industry, the demand for high-quality fresh products never rest, and you can be part of this growing trend as a supplier at PROPLAT.',
      Products: 'My products',
      Purchase_orders: 'Purchase orders',
      Dispute: 'Dispute',
      Warehouses: 'Warehouses',
      Users: 'Users',
      Profile: 'Profile',
      buttonSell: 'I want to sell',
    },
    general: {
      privacy: 'Privacy Policy',
      terms: 'Terms and conditions',
      sections: 'Sections',
      social: 'Social',
      download: 'Download',
      home: 'Home',
      aboutUs: 'About us',
      buyers: 'Buyers',
      providers: 'Suppliers',
    }
  },
  sp: {
    about: {
      welcome: '¿Por qué PROPLAT?',
      description: "Conectamos a agricultores mexicanos con compradores en Estados Unidos, ofreciendo una plataforma integral que facilita la compraventa de productos agrícolas. Nuestra solución abarca aspectos clave como logística, financiamiento y seguros, eliminando intermediarios y garantizando una máxima rentabilidad de manera segura y confiable.",
      platformTitle: 'Plataforma',
      platformSection: 'Disfruta de nuestras plataformas especialmente desarrolladas para el sector del produce, diseñadas para simplificar el proceso de compra y venta de productos.',
      insuranceTitle: 'Seguro',
      insuranceSection: 'Garantizamos la seguridad al contar con vendedores y compradores certificados, utilizamos medidas avanzadas de seguridad en transacciones, mantenemos una infraestructura confiable y establecemos políticas de protección al usuario.',
      logisticTitle: 'Logística',
      logisticSection: 'Gestionamos la logística de manera eficiente mediante un proceso paso a paso diseñado para brindar una experiencia fluida a nuestros usuarios.',
      financeTitle: 'Financiamiento',
      financeSesction: 'Ofrecemos opciones de financiamiento económico a través de un proceso que incluye la solicitud en línea, la aprobación basada en la evaluación crediticia, la elección de planes de pago flexibles.',
    },
    banner: {
      titleSection: 'Revolucionando la industria del produce',
      subtitleSection: 'Aprovechamos la tecnología para hacer que la comercialización de productos frescos sea más eficiente y accesible para todos, mejorando la cadena de suministro y brindando oportunidades tanto a los compradores como a los proveedores.',
      buttonBuyer: 'Descubre mas productos',
      buttonProvider: 'Conviertete en proveedor',
    },
    buyer: {
      titleSection: 'Tu Aliado Estratégico para Compras en la Industria del Produce',
      subtitleSection: 'Nos convertimos en tu solución completa para optimizar el proceso de compras a través de proveedores confiables.',
      Catalog: 'Catálogos',
      Warehouses: 'Almacenes',
      Suppliers: 'Proveedores',
      Orders: 'Pedidos',
      Dispute: 'Historial de disputas',
      User: 'Perfil de Usuario',
      buttonBuy: 'Quiero comprar',
    },
    provider: {
      titleSection: 'Conviértete en vendedor y comienza a multiplicar tus ingresos',
      subtitleSection: 'En la industria del produce, la demanda de productos frescos de alta calidad nunca se detiene, y tú puedes ser parte de esta creciente tendencia como un vendedor en PROPLAT.',
      Products: 'Mis artículos',
      Purchase_orders: 'Órdenes de compra',
      Dispute: 'Disputas',
      Warehouses: 'Almacenes',
      Users: 'Usuarios',
      Profile: 'Perfil de Usuario',
      buttonSell: 'Quiero vender',
    },
    general: {
      privacy: 'Aviso de privacidad',
      terms: 'Términos y condiciones',
      sections: 'SECCIONES',
      social: 'REDES SOCIALES',
      download: 'Descarga',
      home: 'INICIO',
      aboutUs: 'NOSOTROS',
      buyers: 'COMPRADORES',
      providers: 'PROVEEDORES',
    }
  }
};

export default texts;