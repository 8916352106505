import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import axios from 'axios'
import lodash from 'lodash'
import moment from 'moment'
import { mixs } from '@/mixins'
import { texts } from '@/files'
import Toast from 'vue-toastification'
import * as VueGoogleMaps from 'vue2-google-maps'
import 'vue-toastification/dist/index.css'
import "vue-gifplayer/src/gif.css"
import '@/modules'

Vue.config.productionTip = false

// import of mixins for the alert call
Vue.mixin(mixs);

// use of alerts throughout the project
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});

// window.FORMAT_MONEY = function (n, dp, ds, ts) {
//   dp = isNaN(dp = Math.abs(dp)) ? 2 : dp,
//     ds = typeof ds === "undefined" ? "." : ds;
//   ts = typeof ts === "undefined" ? "," : ts;
//   var sign = n < 0 ? "-" : "";
//   var i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(dp)));
//   var j = (j = i.length) > 3 ? j % 3 : 0;

//   return sign +
//     (j ? i.substr(0, j) + ts : "") +
//     i.substr(j).replace(/(\ds{3})(?=\ds)/g, "$1" + ts) +
//     (dp ? ds + Math.abs(n - i).toFixed(dp).slice(2) : "");
// }

window.FORMAT_MONEY = function (n) {
  let nf = Number(n).toFixed(2);
  return nf.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// import of project text files
window.FILE = texts;

// import axios 
window.DB = axios;

// initailize URI for global use
//qa --- dev --- alfa---api.proplat.com
// window.URI = 'http://localhost:3000/alfa'
window.URI = 'https://api.proplat.com/alfa'
window.URIVendors = "https://alfa.vendors.proplat.com/";

window.lodash = lodash

window.MOMENT = moment;

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyArzew42XOo4jWF_0fWBPa-lEhpFn6-OS0',
//     // key: 'AIzaSyAKC6VvI7gKOK5mKruoRxmhz-nWb7AFu6w',
//     libraries: 'places',
//   }
// });

Vue.use(VueGoogleMaps, { load: { key: 'AIzaSyArzew42XOo4jWF_0fWBPa-lEhpFn6-OS0', libraries: 'places' } })

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
