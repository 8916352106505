<template>
  <div>
    <navbar-component />
    <div class="bg-container full-height" v-if="isRegisterEfex">
      <div style="display: flex; flex-direction: row; height: 100%">
        <v-navigation-drawer
          class="sidebar"
          :width="245"
          :mini-variant="miniVariant"
          :expand-on-hover="expandOnHover"
        >
          <v-list-item title="My Application" subtitle="Vuetify">
            <!-- <v-img src="../../assets/logos/logo-bg-white.svg"></v-img> -->
            <v-img
              :src="
                miniVariant
                  ? require('../../assets/logos/logo_small.png')
                  : require('../../assets/logos/logo-bg-white.svg')
              "
            ></v-img>
          </v-list-item>
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <v-btn
              icon
              @click.stop="miniVariant = !miniVariant"
              class="toggle-btn"
              :ripple="false"
            >
              <v-icon>{{ "mdi mdi-menu" }}</v-icon>
              {{ miniVariant ? "" : "Ocultar menú" }}
            </v-btn>
          </div>
          <v-divider></v-divider>

          <template v-for="(section, index) in sections">
            <v-list-group
              :prepend-icon="section.icon"
              :value="false"
              :class="{ 'active-section': selectedMenuItem === section.value }"
              :color="selectedMenuItem === section.value ? '' : '#f0be44'"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ section.title }}</v-list-item-title>
              </template>
              <v-list-item
                v-for="(item, itemIndex) in section.items"
                :key="itemIndex"
                link
                @click="selectedMenuItem = item.value"
                :class="{ 'active-item': selectedMenuItem === item.value }"
                :disabled="item.disabled"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </template>

          <div style="padding-top: 100px"></div>
          <v-divider></v-divider>
        </v-navigation-drawer>

        <div class="full-height" style="width: 100%">
          <div v-if="selectedMenuItem === 'dashboard'">
            <dashboard-cards-layout
              :texts="texts"
              :actualCurrency="actualCurrency"
              :balanceMxn="balanceMxn"
              :balanceUsd="balanceUsd"
              :balance="balance"
              :formatMoney="formatMoney"
              :openModalAddFounds="openModalAddFounds"
              :dialogAddFounds="dialogAddFounds"
              :closeModalAddFounds="closeModalAddFounds"
              :dataAddFounds="dataAddFounds"
              :getActualCurrency="getActualCurrency"
              :actualCurrencyMxn="actualCurrencyMxn"
              :userName="userName"
              :exchangeRateAdjustments="exchangeRateAdjustments"
            ></dashboard-cards-layout>

            <dashboard-table-layout
              :texts="texts"
              :exchangeRateData="exchangeRateData"
              :toggleAll="toggleAll"
              :bSelectedAll="bSelectedAll"
              :changebSelectedAll="changebSelectedAll"
              :actualCurrency="actualCurrency"
              :formatDate="formatDate"
              :balanceMxn="balanceMxn"
              :balanceUsd="balanceUsd"
              :formatMoney="formatMoney"
              :openModalCreate="openModalCreate"
              :closeModalCreate="closeModalCreate"
              :sendToPayNow="sendToPayNow"
              :exchangeRateDataSeparate="exchangeRateDataSeparate"
              :toggleAllSeparated="toggleAllSeparated"
              :bSelectedAllSeparate="bSelectedAllSeparate"
              :sendToPayNowMxn="sendToPayNowMxn"
              :openModal="openModal"
              :itemsSelected="itemsSelected"
              :modalCurrency="modalCurrency"
              :changeModalCurrency="changeModalCurrency"
            ></dashboard-table-layout>

            <dashboard-modals-layout
              :texts="texts"
              :dialogAddFounds="dialogAddFounds"
              :closeModalAddFounds="closeModalAddFounds"
              :dataAddFounds="dataAddFounds"
              :dialogCreate="dialogCreate"
              :dialogPay="dialogPay"
              :editableData="editableData"
              :contacts="contacts"
              :contactData="contactData"
              :showCreateProvider="showCreateProvider"
              :isResponsive="isResponsive"
              :screenWidth="screenWidth"
              :bLoading="bLoading"
              :validateForm="validateForm"
              :saveChanges="saveChanges"
              :closeModalCreate="closeModalCreate"
              :actualCurrency="actualCurrency"
              :itemsSelected="itemsSelected"
              :formatMoney="formatMoney"
              :totalUsd="totalUsd"
              :stepper="stepper"
              :closeModalStepper="closeModalStepper"
              :sendToPay="sendToPay"
              :sendPayEfex="sendPayEfex"
              :e1="e1"
              @update-e1="actualizarE1"
              :isDeferredPayment="isDeferredPayment"
              :changeDeferredPayment="changeDeferredPayment"
              :isPayMxn="isPayMxn"
              :changePayMxn="changePayMxn"
              :sendSeparateEfex="sendSeparateEfex"
              :closeModalPay="closeModalPay"
              :closeDialog="closeDialog"
              :dialogEditProfileActive="dialogEditProfileActive"
              :updateExchangeRate="updateExchangeRate"
              :backStep="backStep"
              :getForwards="getForwards"
              :itemsSelectedForwards="itemsSelectedForwards"
              :isForwardRateCalculated="isForwardRateCalculated"
              :isForwardsPayment="isForwardsPayment"
              :sendForwardPayment="sendForwardPayment"
              :modalCurrency="modalCurrency"
              :exchangeRateAdjustments="exchangeRateAdjustments"
              :formatDate="formatDate"
              :updateTotalUsd="updateTotalUsd"
              :isForwardsActive="isForwardsActive"
            ></dashboard-modals-layout>
          </div>

          <div v-else-if="selectedMenuItem === 'history'">
            <dashboard-history-layout
              :texts="texts"
              :exchangeRateDataPaid="exchangeRateDataPaid"
              :formatDate="formatDate"
              :formatMoney="formatMoney"
              :openReceipt="openReceipt"
            ></dashboard-history-layout>
          </div>

          <div v-else-if="selectedMenuItem === 'activeForwards'">
            <dashboard-forwards-layout
              :texts="texts"
              :exchangeRatesStateForwards="exchangeRatesStateForwards"
              :formatDate="formatDate"
              :formatMoney="formatMoney"
              :openReceipt="openReceipt"
              :screenWidth="screenWidth"
              :editableData="editableData"
              :closeDialog="closeDialog"
              :dialogEditProfileActive="dialogEditProfileActive"
              :updateExchangeRate="updateExchangeRate"
              :modalCurrency="modalCurrency"
              :actualCurrency="actualCurrency"
              :isResponsive="isResponsive"
              :bLoading="bLoading"
              :validateForm="validateForm"
              :openModal="openModal"
              :toggleAllForwards="toggleAllForwards"
              :bSelectedAll="bSelectedAll"
              :sendToPayNowForwards="sendToPayNowForwards"
            ></dashboard-forwards-layout>
          </div>
        </div>

        <!-- <div v-else-if="selectedMenuItem === 'activeForwards'">
          <dashboard-forwards-layout
            :texts="texts"
            :exchangeRatesStateForwards="exchangeRatesStateForwards"
            :formatDate="formatDate"
            :formatMoney="formatMoney"
          ></dashboard-forwards-layout>
        </div> -->
      </div>
    </div>
    <div class="bg-container pb-4 full-height" v-else>
      <v-container class="v-container-fluid full-height full-width" fluid>
        <div class="display-flex align-items-center justify-center flex-column">
          <div class="mt-1">
            <h3>
              Necesitas registrarte en Efex para poder usar esta funcionalidad
            </h3>
          </div>
          <div class="mt-1">
            <h3>Formulario de Registro</h3>
          </div>
          <div class="mt-1">
            <h4>Necesitamos estos datos para poder crear una cuenta</h4>
          </div>
          <div class="pt-2 form-container">
            <v-text-field
              v-for="(field, index) in registerFields"
              :key="index"
              v-model="dataEfex[field.model]"
              :type="field.type"
              :label="texts.register[field.label]"
              outlined
              color="#000000"
              dense
              class="global-inputs mb-3"
              :placeholder="texts.register[field.label]"
              persistent-placeholder
            ></v-text-field>
            <div
              class="display-flex align-items-center justify-center flex-column"
            >
              <v-btn
                @click="createSubAccount(dataEfex)"
                class="button-primary width-100 mon-bold"
                elevation="0"
                >{{ texts.register.register }}</v-btn
              >
            </div>
          </div>
        </div>
      </v-container>
    </div>
    <footer-component />
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DashboardView",
  data() {
    return {
      texts: "",
      actualCurrency: "0",
      actualCurrencyMxn: "0",
      referenceCurrency: "0",
      isLoading: false,
      isRegisterEfex: true,
      exchangeRateData: [],
      exchangeRateDataPaid: [],
      exchangeRateDataSeparate: [],
      balanceMxn: "0",
      balanceUsd: "0",
      balance: "0",
      items: [],
      contacts: [],
      itemsSelected: [],
      itemsSelectedForwards: [],
      isForwardRateCalculated: false,
      bSelectedAll: false,
      dialogAddFounds: false,
      dataAddFounds: {},
      dialogCreate: false,
      dialogPay: false,
      editableData: {},
      totalUsd: 0,
      selectedMenuItem: "dashboard",
      contactData: {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY"
      },
      isForwardsActive: false,
      showCreateProvider: false,
      isResponsive: false,
      screenWidth: 0,
      bLoading: false,
      isDeferredPayment: false,
      stepper: false,
      e1: 1,
      timerCount: 30,
      timerId: null,
      dataEfex: {},
      exchangeRateAdjustments: [],
      exchangeRatesStateForwards: [],
      isPayMxn: false,
      bSelectedAllSeparate: false,
      dialogEditProfileActive: false,
      isForwards: false,
      isForwardsPayment: false,
      userName: "",
      modalCurrency: 0,
      sections: [
        {
          icon: "mdi mdi-view-dashboard",
          value: "operation",
          title: "Operación",
          items: [
            {
              icon: "mdi mdi-view-dashboard",
              value: "dashboard",
              title: "Dashboard",
              disabled: false
            },
            {
              icon: "mdi-forward",
              value: "activeForwards",
              title: "Forwards activos",
              disabled: false
            },
            {
              icon: "mdi-file-document",
              value: "history",
              title: "Historial de pagos",
              disabled: false
            }
          ]
        },
        {
          icon: "mdi-history",
          value: "history",
          title: "Historial",
          items: [
            {
              icon: "mdi-file-document",
              value: "accountStatements",
              title: "Estados de cuenta",
              disabled: true
            },
            {
              icon: "mdi-file-document",
              value: "exchangeRate",
              title: "Conversión wallets",
              disabled: true
            }
          ]
        },
        {
          icon: "mdi-cog",
          value: "control",
          title: "Control",
          items: [
            {
              icon: "mdi-account-group",
              value: "suppliers",
              title: "Proveedores",
              disabled: true
            }
          ]
        }
      ],
      miniVariant: true,
      expandOnHover: false,
      registerFields: [
        { model: "sBankName", type: "text", label: "sBankName" },
        { model: "sAccountNumber", type: "text", label: "sAccountNumber" },
        { model: "sRoutingNumber", type: "text", label: "sRoutingNumber" }
      ]
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 30;
      } else {
        this.screenWidth = 100;
      }

      this.isResponsive = window.innerWidth < 960;
    },
    getActualCurrency: function () {
      this.isLoading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/exchange-rate-global`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        if (!response.data.status) {
          this.mixError(response.data.message);
          return;
        }
        this.referenceCurrency = response.data.reference;
        this.actualCurrency = response.data.mxnRate;
        this.actualCurrencyMxn = response.data.usdRate.toString();
        this.getExchangeRate();
      });
    },
    formatMoney: function (value) {
      let multiplied = value * 100;
      let rounded = Math.round(multiplied);
      let formattedNumber = rounded / 100;
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(formattedNumber);
    },
    getExchangeRate: function () {
      this.isLoading = true;
      this.items = [];

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/?exchangeRateToday=${this.actualCurrency}`,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          this.isForwardsActive = response.data.isForwardsActive;
          this.userName = response.data.user;
          this.isRegisterEfex = response.data.registerInEfex;
          this.exchangeRateDataPaid =
            response.data.exchangeRate.exchangeRatesStatePaid;
          this.exchangeRateData =
            response.data.exchangeRate.exchangeRatesStateToPay;
          this.exchangeRateDataSeparate =
            response.data.exchangeRate.exchangeRatesStateSeparated;
          this.exchangeRatesStateForwards =
            response.data.exchangeRate.exchangeRatesStateForwards;
          this.balanceMxn = response.data.balance.balanceMxn;
          this.balanceUsd = response.data.balance.balanceUsd;
          this.exchangeRateAdjustments = response.data.exchangeRateAdjustments;
          this.balance =
            parseFloat(this.balanceMxn) / parseFloat(this.actualCurrency) +
            parseFloat(this.balanceUsd);
          this.balance = this.balance.toString();
          // this.totalUsd = 0;

          // this.itemsSelected.forEach((item) => {
          //   item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
          //   this.totalUsd += item.dTotalUsdToday;
          // });

          this.mixSuccess(response.data.message);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        })
        .finally(() => {
          this.isLoading = false;
          this.getContacts();
        });
    },
    getContacts: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/contacts-wallet/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      })
        .then((response) => {
          this.contacts = response.data.contacts;
          this.contacts.push({
            id: 0,
            name: "Crear nuevo contacto"
          });
        })
        .catch((error) => {
          // Handle error if needed
        });
    },
    openModalAddFounds() {
      this.dialogAddFounds = true;

      const payload = {
        amount: 100
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/addFounds`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          this.dataAddFounds = response.data.account;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    closeModalAddFounds() {
      this.dialogAddFounds = false;
    },
    toggleAll: function (checked) {
      this.exchangeRateData.forEach((item) => {
        if (
          item.statusName !== "WAITING_FOR_DEPOSIT" ||
          item.statusName !== "PROCESSING"
        ) {
          item.bSelected = checked;
        }
      });
    },
    formatDate: function (date) {
      if (this.selectLanguage === "sp") {
        moment.locale("es");
      } else {
        moment.locale("en");
      }
      let newDate = moment(date);
      let formattedDate = newDate.format("MMMM D, YYYY");
      return formattedDate;
    },
    // formatMoney: function (money) {
    //   return FORMAT_MONEY(money);
    // },
    resetForm() {
      this.editableData = {
        dPurchaseDate: "",
        sProvider: "",
        sInvoiceNumber: "",
        dTotalAmount: "",
        iCreditPeriod: "",
        dInvoiceExchangeRateProtection: ""
      };
    },
    openModalCreate() {
      this.dialogCreate = true;
      this.resetForm();
    },
    validateForm: function () {
      return true;
    },
    saveChanges: function () {
      this.bLoading = true;
      let newdPurchaseDate = this.formatDateToSend(
        this.editableData.dPurchaseDate
      );

      const payload = {
        dPurchaseDate: newdPurchaseDate.toString(),
        sProvider: this.editableData.sProvider,
        sInvoiceNumber: this.editableData.sInvoiceNumber,
        dTotalAmount: parseFloat(this.editableData.dTotalAmount),
        iCreditPeriod: parseInt(this.editableData.iCreditPeriod),
        createContact: this.showCreateProvider,
        contactData: this.contactData,
        dInvoiceExchangeRateProtection:
          this.editableData.dInvoiceExchangeRateProtection
      };
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.dialogCreate = false;
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    formatDateToSend: function (date) {
      let newDate = new Date(date);
      let day = newDate.getDate() + 1;
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      return `${year}-${month}-${day}`;
    },
    changebSelectedAll: function () {
      this.bSelectedAll = !this.bSelectedAll;
    },
    closeModalCreate() {
      this.dialogCreate = false;
      this.showCreateProvider = false;
      this.contactData = {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY"
      };
    },
    sendToPayNow: function () {
      this.isDeferredPayment = false;
      this.sendToPay();
    },
    sendToPay: function () {
      this.itemsSelected = this.exchangeRateData.filter(
        (item) => item.bSelected
      );
      if (this.itemsSelected.length > 0) {
        this.stepper = true;
        this.itemsSelected.forEach((item) => {
          item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
          this.totalUsd += item.dTotalUsdToday;
        });
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    actualizarE1(nuevoValor) {
      this.e1 = nuevoValor;
    },
    closeModalPay() {
      this.dialogPay = false;
      this.totalUsd = 0;
      this.e1 = 1;
    },
    sendToPayNowMxn: function () {
      this.isDeferredPayment = false;
      this.isPayMxn = true;
      this.itemsSelected = this.exchangeRateDataSeparate.filter(
        (item) => item.bSelected
      );
      this.itemsSelected.forEach((item) => {
        item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
        this.totalUsd += item.dTotalUsdToday;
      });

      if (this.itemsSelected.length > 0) {
        this.dialogPay = true;
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    sendToPayNowForwards: function () {
      console.log("sendToPayNowForwards");
      this.isPayMxn = true;
      this.itemsSelected = this.exchangeRatesStateForwards.filter(
        (item) => item.bSelected
      );
      this.itemsSelected.forEach((item) => {
        item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
        this.totalUsd += item.dTotalUsdToday;
      });

      if (this.itemsSelected.length > 0) {
        this.sendPayEfex();
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    closeModalStepper() {
      this.stepper = false;
      this.totalUsd = 0;
      this.e1 = 1;
      this.isForwards = false;
      this.isForwardsPayment = false;
      //this.getActualCurrency();
    },
    changeDeferredPayment() {
      this.isDeferredPayment = !this.isDeferredPayment;
    },
    changePayMxn() {
      this.isPayMxn = !this.isPayMxn;
    },
    sendPayEfex: function () {
      this.bLoading = true;

      if (this.isPayMxn) {
        this.totalUsd = this.totalUsd * this.actualCurrency;
      }

      const payload = {
        pays: this.itemsSelected,
        referenceCurrency: this.referenceCurrency,
        actualCurrency: this.actualCurrency,
        isDeferredPayment: this.isDeferredPayment,
        isPayMxn: this.isPayMxn,
        totalUsd: this.totalUsd
      };

      console.log("payload", payload);

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/sendPayment/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          console.log("response", response);
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.dialogPay = false;
          this.defferedPayment = false;
          this.getExchangeRate();
          this.totalUsd = 0;
          this.isPayMxn = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.data.message, error.response.status);
        });
    },
    openReceipt: function (item) {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/getPaymentRecipt/${item.paymentExchangeRate[0].sPayment_reference}/`,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (response.data && response.data.payment) {
            let base64 = response.data.payment.data;

            const base64Pattern =
              /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/;
            if (base64Pattern.test(base64)) {
              base64 = base64.split(",")[1];
            }

            try {
              const binaryString = window.atob(base64);
              const bytes = new Uint8Array(binaryString.length);
              for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
              }
              const blob = new Blob([bytes], { type: "application/pdf" });

              const url = URL.createObjectURL(blob);
              window.open(url, "_blank");
            } catch (e) {
              console.error("Error al decodificar base64: ", e);
            }
          } else {
            console.error(
              "No se encontraron los datos del PDF en la respuesta"
            );
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    sendSeparateEfex: function () {
      this.bLoading = true;
      const payload = {
        exchangeRateId: this.itemsSelected,
        rateUuid: this.referenceCurrency,
        rateInt: this.actualCurrency
      };

      console.log("payload", payload);

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/separateExchangeRate/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.dialogSeparate = false;
          this.getExchangeRate();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    toggleAllSeparated: function (checked) {
      this.exchangeRateDataSeparate.forEach((item) => {
        item.bSelected = checked;
      });
    },
    toggleAllForwards: function (checked) {
      this.exchangeRatesStateForwards.forEach((item) => {
        console.log("item", item);
        this.$set(item, "bSelected", checked);
      });
    },
    createSubAccount(data) {
      const payload = {
        sBank_name: data.sBankName,
        sAccount_number: data.sAccountNumber,
        sRouting_number: data.sRoutingNumber,
        clabe: data.clabe,
        sBank_mxn_account: data.sBankMxnAccount
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/sub-account/`,
        payload,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    openModal(item) {
      this.totalUsd = 0;
      this.selectedItem = item;
      this.editableData = { ...this.selectedItem };
      this.dialogEditProfileActive = true;
    },
    closeDialog() {
      this.dialogEditProfileActive = false;
    },
    backStep() {
      this.e1 = 1;
    },
    updateExchangeRate: function () {
      this.bLoading = true;
      let newdPurchaseDate = this.formatDateToSend(
        this.editableData.dPurchaseDate
      );
      const payload = {
        sExchangeRateId: this.selectedItem.sExchangeRateId,
        dPurchaseDate: newdPurchaseDate.toString(),
        sProvider: this.editableData.sProvider,
        sInvoiceNumber: this.editableData.sInvoiceNumber,
        dTotalAmount: parseFloat(this.editableData.dTotalAmount),
        iCreditPeriod: parseInt(this.editableData.iCreditPeriod),
        dInvoiceExchangeRateProtection:
          this.editableData.dInvoiceExchangeRateProtection,
        dAmountAdjustment: parseFloat(this.editableData.dAmountAdjustment),
        sCurrencyAdjustment: "MXN"
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/${this.selectedItem.sExchangeRateId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.dialogEditProfileActive = false;
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    changeForwards: function (value) {
      this.isForwards = value;
    },
    getForwards: function () {
      this.bLoading = true;
      const payload = {
        pays: this.itemsSelected,
        referenceCurrency: this.referenceCurrency,
        reverse: true
      };
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/getRateForwards/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
            this.bLoading = false;
            this.isForwardsPayment = false;
          } else {
            this.mixSuccess(response.data.message);
            this.bLoading = false;
            this.isForwardsPayment = true;
            this.isForwardRateCalculated =
              response.data.isForwardRateCalculated;
            this.itemsSelected = response.data.responsePaysEfex;
          }
          this.getExchangeRate();
        })
        .catch((error) => {
          this.bLoading = false;
          this.isForwardsPayment = false;
          this.mixError(error.data.message, 400);
        });
    },
    sendForwardPayment: function () {
      const payload = {
        pays: this.itemsSelected
      };
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/sendPayForward/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.stepper = false;
          this.defferedPayment = false;
          this.getExchangeRate();
          this.totalUsd = 0;
          this.isForwardsPayment = false;
          this.isPayMxn = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    changeModalCurrency: function (value) {
      this.modalCurrency = value;
    },
    updateTotalUsd: function (value) {
      this.totalUsd = value;
    }
  },
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.getActualCurrency();
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    }
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.takeRate[this.selectLanguage];
      }
    },
    "editableData.sProvider": function (newValue) {
      this.showCreateProvider = newValue === 0;
    }
  }
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #fff;
  opacity: 1;
  padding-top: 75px;
  height: 100%;
}
.full-height {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.active {
  background-color: #f0f0f0;
}
/* .active-section .v-list-item__icon {
  color: yellow !important;
  background-color: yellow !important;
}

.active-section .v-list-item__title {
  background-color: yellow !important;
} */

.active-item {
  background-color: #f5f7f9 !important;
}
</style>
